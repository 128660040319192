<template>
  <div class="edit-sale-discount">
    <draggable-dynamic-table v-if="columnsLabel.length > 1"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :in-modal="true"
                             :zero-top-radius="true"
                             @editableColumnClick="handleColumnClick"
                             @row:clicked="handleRowClicked"/>


    <!-- show price prompt -->
    <vs-prompt
      class="prompt p-0"
      :buttons-hidden="true"
      title=""
      :active.sync="showPricePromptStatus"
      @close="showPricePromptStatus = true">

      <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
        <vs-row>
          <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
            <div v-if="checkUserPermissions('sale_addon.update')" @click="handleSetDiscount()">
              <custom-icon icon="CHECK" color="success"/>
            </div>
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
            {{ $t('sales.discount.edit.dynamicModalTitle', {character: selectedItem.row.name || '', term: selectedItem.column.name || ''}) }}
          </vs-col>

          <vs-spacer/>

          <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
            <div @click="showPricePromptStatus = false">
              <custom-icon icon="TIMES-CIRCLE" color="danger"/>
            </div>
          </vs-col>
        </vs-row>
      </div>

      <div class="prompt-content">
        <template>
          <keep-alive>
            <div class="discount-price-input">
              <custom-select-input :label="$t('sales.discount.labels.discount')"
                                   id="insertTermDiscount"
                                   :auto-focus="true"
                                   :has-comma="true"
                                   :regex="selectedItem.priceType.value === 1 ? percentRegex : priceRegex"
                                   :select-default="selectedItem.priceType"
                                   :select-options="discountInputOptions"
                                   @selected="selectedItem.priceType = $event"
                                   @pressEnter="handleSetDiscount"
                                   v-model="selectedItem.price"/>
            </div>
          </keep-alive>
        </template>
      </div>

    </vs-prompt>


    <button id="editSaleDiscount" v-show="false" @click="editSaleAddonPromptStatus = true"/>
    <button id="sendDiscountDataBTN" v-show="false" @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import {addComma, checkUserPermissions, hideLoading, showLoading} from "../../../../../assets/js/functions";
  import {getTerms} from "../../../../../http/requests/club/terms";
  import {getSaleAddons} from "../../../../../http/requests/club/salesAddons";
  import CustomSelectInput from "../../../../../components/customInput/customSelectInput";
  import {editDiscountGroups, getDiscountGroups} from "../../../../../http/requests/club/discountGroups";
  export default {
    name: "editSaleDiscount",
    components: {CustomSelectInput, CustomIcon},
    data () {
      return {
        percentRegex: this.$validator('regex.sale.discount.percent'),
        priceRegex: this.$validator('regex.sale.discount.price'),
        showSaleAddonPromptStatus: false,
        insertNewSaleAddonPromptStatus: false,
        editSaleAddonPromptStatus: false,
        showPricePromptStatus: false,
        selectedItem: {
          column:  {
            field: '',
            name: ''
          },
          row: {
            name: ''
          },
          index: -1,
          price: {
            value: '',
            isValid: true
          },
          priceType: {
            value: 1,
            label: this.$t('sales.discount.labels.percent')
          }
        },
        termsIdList: [],
        discountInputOptions: [
          {
            label: this.$t('sales.discount.labels.percent'),
            value: 1
          },
          {
            label: this.$locale.currency(),
            value: 2
          }
        ],
        filters: {},
        sorts: ['order[0]=created_at,desc'],
        data: [
          {
            name: '',
            term_1: '',
            term_3: ''
          }
        ],
        page: 1,
        total_count: null,
        loadingTimer: 0,
        options: {
          rowKeyField: 'id'
        },
        columnsLabel: [
          {
            field: 'name',
            i18n: 'sales.discount.table.header.name',
            align: 'center',
            width: 'calc((100% / 10) * 2)',
            minWidth: 200,
          },
        ],
      }
    },
    created () {
      // if (checkUserPermissions('character.show')) {
      //   this.getCharacters()
      // }
      this.getDiscountGroups()
      this.getTerms()
    },
    mounted () {
      setTimeout(() => {
        showLoading()
      }, 480)
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      handleSetDiscount () {
        if (!this.selectedItem.price.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('sales.discount.notifications.wrongValue'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }
        let selectedRow = this.data[this.selectedItem.index]
        selectedRow[this.selectedItem.column.field] = this.selectedItem.price.value
        selectedRow[this.selectedItem.column.field] = addComma(this.selectedItem.price.value)
        selectedRow[`${this.selectedItem.column.field}_preContent`] = this.selectedItem.priceType
        selectedRow[`${this.selectedItem.column.field}_type`] = this.selectedItem.priceType
        this.data[this.selectedItem.index] = selectedRow
        let data = this.data
        this.data = []
        setTimeout(() => {
          this.data = data
        }, 200)
        this.showPricePromptStatus = false
        this.$forceUpdate()
      },

      getDiscountGroups () {
        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.saleDiscountsList && this.data.length === 0) this.$refs.saleDiscountsList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.saleDiscountsList && this.data.length > 0) this.$refs.saleDiscountsList.loadMoreStatus = 'Loading'

            getDiscountGroups(this.page, this.filters, this.sorts).then((response) => {
              const discounts = response.data
              let data = []
              discounts.data.forEach((saleAddon) => {

                if (!data[`character_${saleAddon.character.id}`]) {
                  data[`character_${saleAddon.character.id}`] = []
                }
                data[`character_${saleAddon.character.id}`].id = saleAddon.character.id
                data[`character_${saleAddon.character.id}`].name = saleAddon.character.name
                if (saleAddon.price > 0) {
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}`] = addComma(saleAddon.price)
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}_preContent`] = this.discountInputOptions[1]
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}_type`] = this.discountInputOptions[1]
                } else {
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}`] = addComma(saleAddon.percent)
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}_preContent`] = this.discountInputOptions[0]
                  data[`character_${saleAddon.character.id}`][`term_${saleAddon.term.id}_type`] = this.discountInputOptions[0]
                }
              })
              this.data = Object.values(data)

              this.total_count = saleAddons.pagination.total
              this.page = saleAddons.pagination.current_page + 1

              if (response.data.pagination.current_page === 1) {
                const row_index = this.columnsLabel.map((e) => {
                  return e.field
                }).indexOf('rowNumber')
                this.columnsLabel[row_index].footer.value = response.data.pagination.total
              }

              if (this.$refs.saleDiscountsList) this.$refs.saleDiscountsList.loadMoreStatus = ''
            }).catch((error) => {
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else if (this.$refs.saleDiscountsList) this.$refs.saleDiscountsList.loadMoreStatus = 'Danger'
            })
          }
        }, 400)
      },
      setFilter (filters) {
        let filters_list = {}
        Object.keys(filters).forEach((key) => {
          switch (key) {
            case 'name':
              if (filters[key].search !== '') filters_list.name = `${filters[key].search}`
              break

            case 'price':
              if (filters[key].search !== '') filters_list.price = `${filters[key].search},${  filters[key].type.id}`
              break

            case 'character':
              if (filters[key].search.value > 0) filters_list.character = `${  filters[key].search.value}`
              break

            case 'financialStatus':
              if (filters[key].search !== '') filters_list.price = `${  filters[key].search}`
              break
          }
        })

        this.data = []
        this.page = 1
        this.total_count = 0
        this.filters = filters_list
        this.getDiscountGroups()
      },
      setSort (sorts) {
        const sorts_list = []
        Object.keys(sorts).forEach((key) => {
          switch (key) {
            case 'name':
              sorts_list.push(`order[0]=name,${  sorts[key] ? 'desc' : 'asc'}`)
              break

            case 'price':
              sorts_list.push(`order[0]=price,${  sorts[key] ? 'desc' : 'asc'}`)
              break
          }
        })

        if (sorts_list.length === 0) {
          this.sorts.push('order[0]=updated_at,desc')
        }

        this.data = []
        this.page = 1
        this.total_count = 0
        this.sorts = sorts_list
        this.getDiscountGroups()
      },
      getTerms () {
        getTerms().then(response => {
          const terms = response.data.data
          let columnsLabel = JSON.parse(JSON.stringify(this.columnsLabel))
          terms.forEach(term => {
            this.termsIdList.push(term.id)
            columnsLabel.unshift(
              {
                field: `term_${term.id}`,
                headerName: term.name,
                width: 'calc(100% / 10)',
                minWidth: 150,
                editable: true,
              })
          })
          this.columnsLabel = []
          this.columnsLabel = columnsLabel
          hideLoading()
        })
          .catch(error => {
            hideLoading()
          })
      },
      handleColumnClick (column, row, row_index) {
        this.selectedItem = {
          column:  {
            field: column.field,
            name: column.headerName
          },
          row: {
            name: row.name
          },
          price: {
            value: this.data[row_index][column.field] || '0'
          },
          priceType: this.data[row_index][`${column.field}_type`] || {
            value: 1,
            label: this.$t('sales.discount.labels.percent')
          },
          index: row_index
        }
        this.showPricePromptStatus = true
      },
      handleRowClicked (row) {
        // this.selectedRow = row
        // this.showSaleAddonPromptStatus = true
      },
      sendData () {
        if (this.data.length === 0) {
          return false
        }

        let groups = []
        this.data.forEach((item => {
          this.termsIdList.forEach(termId => {
            groups.push({
              term_id: termId,
              character_id: item.id
            })
            if (item[`term_${termId}_type`].value === 2) {
              groups[groups.length - 1].price = parseInt(item[`term_${termId}`].split(',').join('') || '0')
            } else {
              groups[groups.length - 1].percent = parseInt(item[`term_${termId}`].split(',').join('') || '0')
            }
          })
        }))
        const payment = {
          groups: groups
        }
        editDiscountGroups(payment).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('sales.discount.notifications.edit.success'),
            color: 'success',
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400
          })
          this.$emit('edited')
        }).catch(error => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            this.$vs.notify({
              title: this.$t('alert.error.title'),
              text: this.$t('sales.discount.notifications.edit.error'),
              color: 'danger',
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400
            })
          }
        })
      },
      handleReloadList () {
        this.data = []
        this.page = 1
        this.total_count = 0
        this.showSaleAddonPromptStatus = false
        this.insertNewSaleAddonPromptStatus = false
        this.editSaleAddonPromptStatus = false
        this.getDiscountGroups()
      },
      handleClick (id) {
        document.getElementById(id).click()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .edit-sale-discount {
    height: 100%;
  }
  .discount-price-input {
    min-height: 100px;
  }
</style>
