<template>
  <div class="u-input custom-validate-input">
    <label class="u-input-box" :class="[{'is-not-valid': !data.isValid || notValid}, classes || {}]" :style="getStyles">
      <div class="u-input-label-box" :class="{'clickable': clickable}">
        <div class="u-input-label-box-start"></div>
        <div v-if="labelPlaceholder || label" class="u-input-label-box-center" :class="[labelPosition]">
          <span class="u-input-label text-subtitle" :class="[{'is-focus': data.value.toString().length || label, 'text-body-1': labelPosition === 'inside'}]">{{ labelPlaceholder || label }}</span>
        </div>
        <div class="u-input-label-box-end"></div>
      </div>
      <input type="text"
             :id="id"
             :class="[align === 'right' ? 'text-left' : 'text-right', {'disabled': disabled}, {'rtl': !rtl}, {'ltr': rtl}]"
             :style="inputStyles"
             @input="handleInputValue"
             @click="$emit('click')"
             @keydown.enter="handleKeydownEnter"
             :placeholder="placeholder || ''"
             :disabled="disabled"
             :autocomplete="autoComplete"
             :name="Math.random()"
             v-model="data.value">
      <div class="select-part" :class="{'is-not-valid': !data.isValid || notValid}" @mouseleave="handleCloseDropDown(true)" @mouseenter="handleCloseDropDown(false)">
        <div class="selected" :class="{ 'open': open, 'disabled': disabled}" :style="styles" @click="!disabled ? open = !open : '', handleOpenSelect()">
          <div>
            {{ typeof selected === 'string' ? JSON.parse(selected).label : selected.label }}
          </div>
        </div>

        <div class="items" id="selectItems" :class="{ selectHide: !open }">
          <div
            v-for="(option, i) of selectOptions"
            :key="i"
            v-show="!option.hasOwnProperty('show') || option.show"
            @click="
          selected = option,
          open = false,
          $emit('selected', option),
          search = option.label
        ">
            {{ typeof option === 'string' ? JSON.parse(option).label : option.label }}
          </div>
        </div>
      </div>
    </label>
    <span class="invalid-text-helper" v-if="!data.isValid && this.data.value.length > 0" >
      {{ invalidText || this.$t('customSelectInput.invalidText') }}
    </span>
  </div>
</template>

<script>
import {addComma} from "../../assets/js/functions";

export default {
  name: 'customSelectInput',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    rtl: {
      type: Boolean,
      default: () => { return false }
    },
    id: {
      type: String,
      default: () => { return '' }
    },
    disabled: {
      type: Boolean,
      default: () => { return false }
    },
    autoComplete: {
      type: Boolean,
        default: () => { return false }
    },
    autoFocus: {
      type: Boolean,
        default: () => { return false }
    },
    label: {
      type: String,
      default: () => { return '' }
    },
    placeholder: {
      type: String,
      default: () => { return '' }
    },
    labelPlaceholder: {
      type: String,
      default: () => { return '' }
    },
    labelPosition: {
      type: String,
      default: 'outline'
    },
    clickable: {
      type: Boolean,
      default: () => { return false }
    },
    align: {
      type: String,
      default: () => { return 'left' }
    },
    isEmpty: {
      type: Boolean,
      default: () => { return false }
    },
    notValid: {
      type: Boolean,
      default: () => { return false }
    },
    hasComma: {
      type: Boolean,
      default: () => { return false }
    },
    regex: {
      type: RegExp,
      default: () => { return null }
    },
    invalidText: {
        type: String,
        default: () => { return '' }
    },
    value: {
      type: Object,
      default () { return {} }
    },
    selectOptions: {
      type: Array,
      required: true
    },
    selectDefault: {},
    classes: {
      type: Object,
      default () { return {} }
    },
    styles: {
      type: Object,
      default () { return {} }
    },
    inputStyles: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      firstVisit: true,
      firstVisitTimer: 0,
      closeDropdownTimer: 0,
      selected: this.selectDefault ? this.selectDefault : this.selectOptions.length > 0 ? this.selectOptions[0] : null,
      open: false,
      search: '',
      data: {
        value: '',
        isValid: false
      }
    }
  },
  computed: {
    getStyles () {
      const styles = JSON.parse(JSON.stringify(this.styles))
      if (!styles.width) {
        // styles.width = '230px'
      }

      return styles
    }
  },
  created () {
    this.search = this.selected.label
    if (this.selectDefault) this.search = this.selectDefault.label
    if (Object.keys(this.value).length) {
      this.initValues()
    }
    if (this.isEmpty) {
      this.data.isValid = true
    } else {
      this.validateValue()
    }

    if (this.id.length > 0 && this.autoFocus) {
      setTimeout(() => {
        window.document.getElementById(this.id).focus()
      }, 100)
    }
  },
  methods: {
    handleOpenSelect () {
      setTimeout(() => {
        window.document.getElementById('selectItems').scrollIntoView()
      }, 100)
    },
    handleCloseDropDown (status) {
      if (status) {
        this.closeDropdownTimer = setTimeout(() => {
          !this.disabled ? this.open = false : ''
        }, 1000)
      } else {
        clearTimeout(this.closeDropdownTimer)
      }
    },
    handleInputValue () {
      if (this.hasComma) this.addComma()
      // this.data.value = this.data.value.toString().replace(this.regex, '')
      this.validateValue()
      this.$emit('input', {value: this.data.value.split(',').join(''), isValid: this.data.isValid})
    },
    addComma () {
      this.data.value = addComma(this.data.value) || '0'
    },
    validateValue () {
      if (this.regex !== null && !this.notValid) {
        let data = this.data.value
        if (this.hasComma) {
          data = this.data.value.split(',').join('')
        }
        this.data.isValid = this.regex.test(data)
      } else if (this.isEmpty) {
        this.data.isValid = true
      } else if (this.notValid) {
        this.data.isValid = false
      } else {
        this.data.isValid = this.data.value.toString().length > 0
      }
    },
    initValues () {
      this.data.value = this.value.value
      if (this.hasComma) this.addComma()
      this.validateValue()
    },
    handleKeydownEnter () {
      this.$emit('pressEnter')
    },
  },
  watch: {
    value: {
      handler () {
        this.initValues()
      },
      deep: true
    },
    'data.isValid': {
      handler () {
        this.$emit('input', {value: this.data.value.toString().replaceAll(',', ''), isValid: this.data.isValid})
      }
    },
    regex: {
      handler () {
        this.validateValue()
      }
    },
    selectDefault: {
      handler () {
        if (this.selectDefault && this.selectDefault.label !== '') {
          this.selected = this.selectDefault

          if (this.searchable) {
            this.search = this.selectDefault.label === '-' ? '' : this.selectDefault.label
          }
        }
      },
      deep: true
    },
    search: {
      handler (val) {
        if (this.searchable) {
          clearTimeout(this.firstVisitTimer)
          this.firstVisitTimer = setTimeout(() => {
            this.firstVisit = false
          }, 500)

          if (!this.firstVisit) {
            // this.open = true

            this.selectOptions.forEach((option) => {

              option.show = option.label.toString().indexOf(val) !== -1
            })
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-validate-input {
  position: relative;
  //margin: 15px 0;

  label {
    display: block;
    padding: 6px 10px 6px 5px;
    border-radius: .5rem;
    transition: all .3s ease;

    &.is-not-valid {
      border-color: #b00000;
      color: #b00000;

      input {
        color: #b00000;
      }
    }

    span {
      position: absolute;
      padding: 0 5px;
      -webkit-transition: all .3s ease;
      -moz-transition: all .3s ease;
      -o-transition: all .3s ease;
      -ms-transition: all .3s ease;
      transition: all .3s ease;
      top: 7px;

      &.is-focus {
        font-size: 12px;
        top: -10px;
      }
    }

    input {
      width: calc(100% - 100px);
      background: transparent;
      border: none;
      outline: none !important;
      text-align: right;

      &.disabled {
        opacity: 0.75;
      }
    }

    .select-part {
      position: absolute;
      top: 0;
      right: 0;
      width: 100px;
      height: 100%;
      display: flex;
      align-items: center;
      border-left: 1px solid #cecece;

      &.is-not-valid {
        border-color: #b00000;
      }

      .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #fff;
        color: #000;
        border-radius: 6px;
        min-height: 30px;
        width: 100%;
        padding: 0 10px;
        //border: 1px solid #10163a;
        /*padding-right: 1em;*/
        text-align: center;
        cursor: pointer;
        user-select: none;
        z-index: unset !important;
        transition: background-color .3s ease;

        input {
          padding-left: 14px !important;
          padding-right: 14px !important;
          transition: background-color .3s ease;
        }

        .cancel-search {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 7px;
          margin: auto;
          display: block;
          height: fit-content;
        }

        &.open {
          //border: 1px solid #10163a;
          //box-shadow: 0px 5px 10px black;
          background-color: #ffffff;
          border-radius: 6px 6px 0 0;
          z-index: 200 !important;

          input {
            background-color: #ffffff !important;
          }
        }

        &.disabled {
          opacity: 0.5;
        }

        &.danger {
          border: 1px solid #b00000 !important;
        }
      }

      .selected:after {
        position: absolute;
        content: "";
        top: calc(100% / 2 - 3px);
        right: 0.5em;
        width: 0;
        height: 0;
        border: 5px solid;
        border-color: #000 transparent transparent transparent;
      }

      .items {
        color: #000;
        border-radius: 0 0 6px 6px;
        overflow-x: hidden;
        overflow-y: auto;
        //border-right: 1px solid #10163a;
        //border-left: 1px solid #10163a;
        //border-bottom: 1px solid #10163a;
        box-shadow: 0 5px 10px -5px gray;
        position: absolute;
        background-color: #ffffff;
        top: 32px;
        left: 0;
        right: 0;
        z-index: 11000;
        max-height: 250px;

        &::-webkit-scrollbar {
          display: block;
          border-radius: .5rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }

        div {
          color: #000;
          /*padding-left: 1em;*/
          line-height: 35px;
          text-align: center;
          cursor: pointer;
          user-select: none;
          border-top: 1px solid #cdcdcd;

          &:hover {
            transition: all 1s cubic-bezier(0.39, 0.58, 0.57, 1);
            background-color: #cdcdcd;
          }
        }
      }

      .custom-select-overflow {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 10000;
      }
    }
  }

  .invalid-text-helper {
    font-size: 11px;
    color: #b00000;
  }
}
</style>
